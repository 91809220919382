<template>
  <v-overlay :value="showOverlay">
    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OverlayLoaderGlobal',

  data: () => ({}),

  computed: {
    ...mapState('profile', ['user']),
    ...mapState('init', ['isSateInit']),
    ...mapState('logs', ['isOverlayLoaderActive']),

    showOverlay() {
      return (!this.isSateInit && this.user) || this.isOverlayLoaderActive
    },
  },
}
</script>

<style scoped></style>
